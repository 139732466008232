export default class Paginate {
    models = [];

    currentPage;

    lastPage;

    perPage;

    total;

    related;

    model;

    filters = [];

    constructor(models, currentPage, lastPage, perPage, total, related, model, filters = []) {
      this.models = models
      this.currentPage = currentPage
      this.lastPage = lastPage
      this.perPage = perPage
      this.total = total
      this.related = related
      this.model = model
      this.filters = filters
    }

    // eslint-disable-next-line no-unused-vars
    static async initPagination(className, related = [], page = 1, filters = []) {
      return className.paginate(page, filters)
    }

    async reloadPage() {
      return this.model.paginate(this.currentPage, this.filters)
    }

    isLastPage() {
      return this.currentPage === this.lastPage
    }

    isFirstPage() {
      return this.currentPage === 1
    }

    async nextPage() {
      this.currentPage += 1
      return this.model.paginate(this.currentPage, this.filters)
    }

    async prevPage() {
      this.currentPage -= 1
      return this.model.paginate(this.currentPage, this.filters)
    }

    async gotoPage(page) {
      this.currentPage = page
      return this.model.paginate(this.currentPage, this.filters)
    }
}
