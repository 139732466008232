import Vue from 'vue'
import VueRouter from 'vue-router'
import { getHomeRouteForLoggedInUser, isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/admin-dashboard',
      name: 'home',
      component: () => import('@/views/modules/DashboardView.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
        // action: ['VIEW_INDIVIDUAL_DASHBOARD', 'VIEW_GENERAL_DASHBOARD'],
      },
    },

    {
      path: '/',
      name: 'company-home',
      component: () => import('@/views/modules/DashboardView.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        resource: 'Company',
        action: 'admin',
        // action: ['VIEW_INDIVIDUAL_DASHBOARD', 'VIEW_GENERAL_DASHBOARD'],
      },
    },

    {
      path: '/users',
      name: 'usersView',
      component: () => import('@/views/modules/UsersView.vue'),
      meta: {
        pageTitle: 'Usuarios',
        breadcrumb: [
          {
            text: 'Usuarios',
            active: true,
          },
        ],
        resource: 'USERS',
        action: 'VIEW_USERS',
      },
    },
    {
      path: '/companies',
      name: 'companiesView',
      component: () => import('@/views/modules/CompaniesView.vue'),
      meta: {
        pageTitle: 'Compañías',
        breadcrumb: [
          {
            text: 'Compañías',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/plans',
      name: 'plansView',
      component: () => import('@/views/modules/PlansView.vue'),
      meta: {
        pageTitle: 'Planes',
        breadcrumb: [
          {
            text: 'Planes',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },

    /**
         * company routes
         */

    {
      path: '/movements',
      name: 'cycleMovementsView',
      component: () => import('@/views/modules/CycleMovementsView.vue'),
      meta: {
        pageTitle: 'Movimientos',
        breadcrumb: [
          {
            text: 'Movimientos',
            active: true,
          },
        ],
        resource: 'Company',
        action: 'admin',
      },
    },

    {
      path: '/reserves',
      name: 'reservesView',
      component: () => import('@/views/modules/ReservesView.vue'),
      meta: {
        pageTitle: 'Reservas',
        breadcrumb: [
          {
            text: 'Reservas',
            active: true,
          },
        ],
        resource: 'Company',
        action: 'admin',
      },
    },

    {
      path: '/periods',
      name: 'periodsView',
      component: () => import('@/views/modules/PeriodsView.vue'),
      meta: {
        pageTitle: 'Periodos',
        breadcrumb: [
          {
            text: 'Periodos',
            active: true,
          },
        ],
        resource: 'Company',
        action: 'admin',
      },
    },

    {
      path: '/account-types',
      name: 'accountTypesView',
      component: () => import('@/views/modules/AccountTypesView.vue'),
      meta: {
        pageTitle: 'Tipos de cuenta',
        breadcrumb: [
          {
            text: 'Tipos de cuenta',
            active: true,
          },
        ],
        resource: 'Company',
        action: 'admin',
      },
    },
    {
      path: '/cycles',
      name: 'cyclesView',
      component: () => import('@/views/modules/CyclesView.vue'),
      meta: {
        pageTitle: 'Ciclos',
        breadcrumb: [
          {
            text: 'Ciclos',
            active: true,
          },
        ],
        resource: 'Company',
        action: 'admin',
      },
    },
    {
      path: '/customers',
      name: 'customersView',
      component: () => import('@/views/modules/CustomersView.vue'),
      meta: {
        pageTitle: 'Clientes',
        breadcrumb: [
          {
            text: 'Clientes',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'VIEW_CUSTOMERS',
      },
    },
    {
      path: '/customers/create',
      name: 'customerFormView',
      component: () => import('@/views/modules/CustomerFormView.vue'),
      meta: {
        pageTitle: 'Crear cliente',
        breadcrumb: [
          {
            text: 'Clientes',
            to: '/customers',
            active: false,
          },
          {
            text: 'Crear cliente',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'CREATE_CUSTOMERS',
      },
    },

    {
      path: '/customers/edit/:id',
      name: 'customerEditFormView',
      component: () => import('@/views/modules/CustomerFormView.vue'),
      meta: {
        pageTitle: 'Editar cliente',
        breadcrumb: [
          {
            text: 'Clientes',
            to: '/customers',
            active: false,
          },
          {
            text: 'Editar cliente',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'EDIT_CUSTOMERS',
      },
    },

    {
      path: '/savings-accounts',
      name: 'savingAccountsView',
      component: () => import('@/views/modules/DebitAccountsView.vue'),
      meta: {
        pageTitle: 'Cuentas',
        breadcrumb: [
          {
            text: 'Cuentas',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'EDIT_CUSTOMERS',
        accountType: '1',
      },
    },
    {
      path: '/credit-accounts',
      name: 'creditAccountsView',
      component: () => import('@/views/modules/AccountsView.vue'),
      meta: {
        pageTitle: 'Cuentas',
        breadcrumb: [
          {
            text: 'Cuentas',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'EDIT_CUSTOMERS',
        accountType: '2',
      },
    },

    {
      path: '/accounts/create/:accountType',
      name: 'accountsFormView',
      component: () => import('@/views/modules/AccountFormView.vue'),
      meta: {
        pageTitle: 'Crear cuenta',
        breadcrumb: [
          {
            text: 'Cuentas',
            to: '/accounts',
            active: false,
          },
          {
            text: 'Crear cuenta',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'CREATE_CUSTOMERS',
      },
    },

    {
      path: '/accounts/edit/:id',
      name: 'accountsEditFormView',
      component: () => import('@/views/modules/AccountFormView.vue'),
      meta: {
        pageTitle: 'Editar cuenta',
        breadcrumb: [
          {
            text: 'Cuentas',
            to: '/accounts',
            active: false,
          },
          {
            text: 'Editar cuenta',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'CREATE_CUSTOMERS',
      },
    },
    {
      path: '/loan-requests',
      name: 'loanRequestsListView',
      component: () => import('@/views/modules/LoanRequestsView.vue'),
      meta: {
        pageTitle: 'Solicitudes de crédito',
        breadcrumb: [
          {
            text: 'Solicitudes de crédito',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'CREATE_CUSTOMERS',
      },
    },
    {
      path: '/loan-request',
      name: 'loanRequestsView',
      component: () => import('@/views/modules/CreateLoanRequestView.vue'),
      meta: {
        pageTitle: 'Crear solicitud de crédito',
        breadcrumb: [
          {
            text: 'Crear solicitud de crédito',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'CREATE_CUSTOMERS',
      },
    },

    {
      path: '/accounts/:id/transactions/',
      name: 'accountTransactionsView',
      component: () => import('@/views/modules/TransactionsView.vue'),
      meta: {
        pageTitle: 'Transacciones de cuenta',
        breadcrumb: [
          {
            text: 'Cuentas',
            to: '/savings-accounts',
            active: false,
          },
          {
            text: 'Transacciones de cuenta',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'CREATE_CUSTOMERS',
      },
    },

    {
      path: '/configuration',
      name: 'configView',
      component: () => import('@/views/modules/ConfigVue.vue'),
      meta: {
        pageTitle: 'configuración',
        breadcrumb: [
          {
            text: 'Configuración del sistema',
            active: true,
          },
        ],
        resource: 'CUSTOMERS',
        action: 'CREATE_CUSTOMERS',
      },
    },

    /**
         * No auth routes
         */

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        resource: 'No-Auth',
        action: 'read',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Company',
        action: 'admin',
      },
    },

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Router Before Each hook for route protection
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
