import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import BooleanField from '@/dc-it/types/BooleanField'
import helpers from '@/dc-it/services/helpers'

export default class Cycle extends Model {
    endpoint = 'cycles';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('start_date', 'Fecha de inicio', 'date'),
      new Field('end_date', 'Fecha de fin', 'date'),
      new Field('cash_fund', 'Fondo de caja', 'number').applyMask(val => `$ ${helpers.numberFormat(val)}`),
      new BooleanField('is_active', 'Activo', 1),
    ];

    clone = () => Cycle;
}
